html,
body,
#root {
  min-height: 100vh;
  width: 100%;
  background-color: #f7fafb;
  color: #30404d;

  font-family: 'Montserrat', Helvetica, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Oxygen, Ubuntu, Arial, sans-serif;
  font-size: 14px;

  p {
    &.ql-align-center {
      text-align: center;
    }
    &.ql-indent-1 {
      padding-left: 40px;
    }
    &.ql-indent-2 {
      padding-left: 80px;
    }
    &.ql-indent-3 {
      padding-left: 120px;
    }
    &.ql-indent-4 {
      padding-left: 160px;
    }
    &.ql-indent-5 {
      padding-left: 200px;
    }
    &.ql-indent-6 {
      padding-left: 240px;
    }
    &.ql-indent-7 {
      padding-left: 280px;
    }
    &.ql-indent-8 {
      padding-left: 320px;
    }
    &.ql-indent-9 {
      padding-left: 360px;
    }
  }
}

h1,
h2,
h3 {
  font-weight: 600;
}

::selection {
  color: white;
  background: #ffb82b !important;
}

::-moz-selection {
  color: white;
  background: #ffb82b !important;
}

a,
a:hover,
a:active {
  color: #30404d;
}

button {
  background-color: #fff;
}

.bp3-toast[class*='bp3-intent-'] a {
  color: white;
  text-decoration: underline;
}

.bp3-toast-container {
  position: fixed !important; /* Ensures the toast is always visible despite the scroll position */
}

.bp3-tooltip.bp3-intent-warning .bp3-popover-content {
  background-color: #ffb82b !important;
}
.bp3-tooltip.bp3-intent-warning .bp3-popover-arrow-fill {
  fill: #ffb82b !important;
}

.bp3-input:focus,
.bp3-input.bp3-active {
  box-shadow: 0 0 0 1px rgb(238, 128, 50), 0 0 0 3px rgba(238, 128, 50, 0.3),
    inset 0 1px 1px rgba(16, 22, 26, 0.2);
}

.bp3-drawer-header .bp3-icon {
  color: #266dd3;
}

.bp3-drawer-header .bp3-heading {
  color: #30404d;
}

.bp3-drawer-header + div {
  overflow-y: auto !important;
}

.bp3-popover .bp3-popover-content {
  overflow-y: auto;
  max-height: 580px;
}

/* To make select elements full width */
.bp3-popover-target {
  width: 100%;
}

.bp3-dialog {
  width: auto;
  max-width: 80%;
}

/* Switches */
.bp3-control.bp3-switch input:checked ~ .bp3-control-indicator {
  background: #007fa6;
}

.updates-daterange-picker .bp3-control-group {
  flex-direction: column;
}

.updates-daterange-picker .bp3-input-group {
  margin-bottom: 5px;
}

.self-email-daterange-picker .bp3-control-group {
  flex-direction: column;
}

.self-email-daterange-picker .bp3-input-group {
  margin-bottom: 5px;
}

.note-card-date-input .bp3-timepicker-divider-text,
.note-card-date-input .bp3-timepicker-minute {
  display: none;
}

/* Used in the mobile menu to display the main nav when on mobile */
@media (min-width: 1060px) {
  .mobile-only {
    display: none;
  }
}

@keyframes appear-down {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes appear-up {
  from {
    opacity: 0;
    transform: translateY(200px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* These are used by the Presentation pages during transitions */
.fade-enter {
  opacity: 0;
  /* transform: translateY(200px); */
}

.fade-exit,
.fade-exit-active {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: all 500ms 0ms ease-out;
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
